a:hover {
				text-decoration: none;
			}
			body, html {
				height: 100%;
				 -webkit-touch-callout: none;
				    -webkit-user-select: none;
				    -moz-user-select: none;
				    -ms-user-select: none;
				    user-select: none;
					font-family: "microsoft yahei";
					background: #F7F7F7;
			}
			header {
				width: 100%;
				border-top: 2px solid #FFEA00;
				padding: 15px 0;
				background: #ffffff;
			}
			.headCon {
				width: 60%;
				margin: 0 auto;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
			.rightBar>ul {
				display: flex;
				align-items: center;
			}
			.rightBar>ul>li {
				    padding: 10px 22px;
				    border-radius: 4px;
					margin-right: 15px;
			}
			.rightBar>ul>li.h_login {
				background: #eeeeee;
				
			}
			.rightBar>ul>li.h_login>a {
				color: #222222;
			}
			.rightBar>ul>li.register {
				background: #222222;
				color: #FFFFFF;
				font-weight: bold;
			}
			.rightBar>ul>li.register>a {
				color: #ffffff;
			}
			.content {
				width: 60%;
				margin: 0 auto;
			}


			.footer {
				width: 100%;
				text-align: center;
				// position: fixed;
				// bottom: 0;
				background: #F7F7F7;
				padding: 30px 0;
			}
			.footer .copyRight {
				text-align: center;
				color: #888;
				font-size: 12px;
				// margin-bottom: 10px;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.footer .copyRight a {
				color: #888;
			}