@import url(https://img.hxdrive.net/themes/hybrid/css/web_common.css);
/* #container{height: 100vh} */
.footer{
	/* width: 100vw;text-align: center;
	height: 50px;
	line-height: 25px; */
}




.main {
	width: 100%;
	/* height: 100%;
	background-color: #eaeaea; */
	color: #222222;
}

.titBox {
	display: flex;
	align-items: center;
	padding: 10px 0;
}

.iconBox img {
	/* width: 25%; */
	margin-right: 5px;
}

.tit {
	font-size: 18px;
	padding-right: 20px;
}

.coverBook {
	/* width: 200px;
	height: 260px; */
	/* background-image: url("~@/assets/images/recommendIcon.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: left; */
	
}

.listBook {
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	margin: 20px 0;
	zoom: 1;
	/* text-align: center; */

}

.listBook a {
	color: #555555;
}

.listBook:after { clear:both;content:'';display:block;width:0;height:0;visibility:hidden; }

.listBook>li {
	width: 200px;
	/* text-align: center; */
	display: inline-block;
	/* word-wrap:break-word; */
	word-wrap:break-word;
	margin: 20px 0;
}

.coverBook img {
	max-width: 100%;
	max-height: 260px;
	border-radius: 5%;
}

.titBook {
	/* width: 60%; */
	font-size: 14px;
	text-align: left;
	margin-top: 10px;
	display: inline-block;
}

.iconBox {
	width: 25%;
	-webkit-text-emphasis: none;
	        text-emphasis: none;
	display: flex;
	align-items: center;
}

.iconBox > p {
	position: relative;
	color: #999999;
	font-size: 12px;
}

.linear::before {
	content: "";
	position: absolute;
	top: 20px;
	left: 0;
	width: 100%;
	height: 0.25em;
	background: linear-gradient(
			135deg,
			transparent,
			transparent 45%,
			#999999,
			transparent 55%,
			transparent 100%
		),
		linear-gradient(
			45deg,
			transparent,
			transparent 45%,
			#999999,
			transparent 55%,
			transparent 100%
		);
	background-size: 0.5em 0.5em;
	background-repeat: repeat-x, repeat-x;
}
a:hover{text-decoration:none}body,html{height:100%;-webkit-touch-callout:none;-webkit-user-select:none;-ms-user-select:none;user-select:none;font-family:"microsoft yahei";background:#F7F7F7}header{width:100%;border-top:2px solid #FFEA00;padding:15px 0;background:#ffffff}.headCon{width:60%;margin:0 auto;display:flex;justify-content:space-between;align-items:center}.rightBar>ul{display:flex;align-items:center}.rightBar>ul>li{padding:10px 22px;border-radius:4px;margin-right:15px}.rightBar>ul>li.h_login{background:#eeeeee}.rightBar>ul>li.h_login>a{color:#222222}.rightBar>ul>li.register{background:#222222;color:#FFFFFF;font-weight:bold}.rightBar>ul>li.register>a{color:#ffffff}.content{width:60%;margin:0 auto}.footer{width:100%;text-align:center;background:#F7F7F7;padding:30px 0}.footer .copyRight{text-align:center;color:#888;font-size:12px;display:flex;justify-content:center;align-items:center}.footer .copyRight a{color:#888}

